import proj4 from 'proj4';

const GetLAgeojson = async (LAD23CD) => {
  try {
    // Define the REST endpoint and parameters
    const url = "https://services1.arcgis.com/ESMARspQHYMw9BZ9/ArcGIS/rest/services/Local_Authority_Districts_December_2023_Boundaries_UK_BFE/FeatureServer/0/query";
    const params = {
      where: `LAD23CD = '${LAD23CD}'`,  // Query condition
      outFields: '*',  // Fields to return
      f: 'json'  // Format of the response
    };

    // Construct the query string
    const queryString = new URLSearchParams(params).toString();

    // Send the request
    const response = await fetch(`${url}?${queryString}`);
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    const data = await response.json();

    // Initialize the transformer
    proj4.defs("EPSG:27700", "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs");
    proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");
    const transformer = proj4("EPSG:27700", "EPSG:4326");

    // Convert the coordinates
    data.features.forEach(feature => {
      const geometry = feature.geometry;
      if (geometry.x !== undefined && geometry.y !== undefined) {
        const [lon, lat] = transformer.forward([geometry.x, geometry.y]);
        geometry.x = lon;
        geometry.y = lat;
      } else if (geometry.rings) {
        geometry.rings = geometry.rings.map(ring => 
          ring.map(([x, y]) => transformer.forward([x, y]))
        );
      }
    });

    // Merge all rings into a single Polygon
    const allRings = data.features.reduce((acc, feature) => {
      if (feature.geometry.rings) {
        acc.push(...feature.geometry.rings);
      }
      return acc;
    }, []);

    // Convert to GeoJSON format
    const geojson = {
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: allRings
        },
        properties: {} // You can add properties if needed
      }]
    };
    console.log('GeoJSON:', geojson);

    return geojson;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};

export default GetLAgeojson;