import React, { createContext, useState, useEffect } from 'react';
import GetLAgeojson from './components/GetLAgeojson';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [clickedFeature, setClickedFeature] = useState(null);
  const [capacityResults, setCapacityResults] = useState(null);
  const [addedSites, setAddedSites] = useState([]);
  const [targetHouses, setTargetHouses] = useState(0);
  const [settlementName, setSettlementName] = useState('');
  const [lageojson, setLageojson] = useState(null);
  const [rings, setRings] = useState(null);
  const [latorender, setLatorender] = useState(null);

  useEffect(() => {
    // Function to fetch settlement data from the API
    const fetchSettlementData = async () => {
      try {
        // Get today's date in YYYY-MM-DD format
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(today.getDate()).padStart(2, '0');
        const todayStr = `${year}-${month}-${day}`;

        // Make the API request with the date query parameter
        const response = await fetch(`https://us-central1-falafel-426018.cloudfunctions.net/getSettlementHouseholds?date=${todayStr}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // Log the data to debug
        console.log('API response data:', data);

        // Set latorender as data.LAD23CD
        setLatorender(data[0].LAD23CD);

        // Check if data is an array and has at least one element
        if (Array.isArray(data) && data.length > 0) {
          const firstEntry = data[0];
          console.log('First entry:', firstEntry);
          if (firstEntry.SettlementName && firstEntry.SettlementHouseholds) {
            setSettlementName(firstEntry.SettlementName);
            setTargetHouses(Number(firstEntry.SettlementHouseholds)); // Ensure numeric value
          } else {
            console.error('Unexpected data format:', firstEntry);
          }
        } else {
          console.error('Unexpected data format:', data);
        }
      } catch (error) {
        console.error('Error fetching settlement data:', error);
      }
    };

    // Fetch settlement data when the component mounts
    fetchSettlementData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (latorender) {
        const data = await GetLAgeojson(latorender);
        setLageojson(data);
        console.log('Fetched GeoJSON data:', data);
      }
    };

    fetchData();
  }, [latorender]);

  return (
    <AppContext.Provider
      value={{
        clickedFeature,
        setClickedFeature,
        capacityResults,
        setCapacityResults,
        addedSites,
        setAddedSites,
        targetHouses,
        setTargetHouses,
        settlementName,
        setSettlementName,
        lageojson,
        latorender,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};