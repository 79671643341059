import React, { useEffect, useState, useRef, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Map from './components/Map';
import { AppContext, AppProvider } from './AppContext';
import DataSummary from './components/DataSummary';
import Game from './components/Game'; // Import the Game component
import BottomBar from './components/BottomBar';
import Home from './components/Home';
import './App.css';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { Toaster } from 'react-hot-toast';

// Firebase configuration using environment variables
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const AppContent = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isMapVisible, setIsMapVisible] = useState(false);
  const navigate = useNavigate();
  const gameRef = useRef(null);
  const { setClickedFeature } = useContext(AppContext);

  useEffect(() => {
    const userConsent = localStorage.getItem('userConsent');
    if (userConsent === 'accepted') {
      // Initialize analytics tracking here
      console.log('Analytics tracking initialized');
    }
  }, []);

  useEffect(() => {
    logEvent(analytics, 'notification_received');
  }, []);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const toggleComponent = () => {
    navigate('/');
    setIsMapVisible(!isMapVisible);
  };

  const handleTabClick = (tabName) => {
    if (tabName.toLowerCase() === 'map') {
      setClickedFeature(null); // Reset clickedFeature if navigating to the map tab
    }
    navigate(`/${tabName.toLowerCase()}`); // Navigate to the specified tab
  };

  const handleMapClick = () => {
    setTimeout(() => {
      setIsMapVisible(false);
    }, 300); // Add a delay of 300ms
  };

  return (
    <div className="App">
      <div className="button-container">
        <button className="toggle-button" onClick={() => handleTabClick('')}>
          Home
        </button>
        <button className="toggle-button" onClick={() => handleTabClick('Map')}>
          Map
        </button>
        {/* <button className="toggle-button" onClick={() => handleTabClick('Data')}>
          Data
        </button> */}
        <button className="toggle-button" onClick={() => handleTabClick('Game')}>
          Score Board
        </button>
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/map" element={
          <div className={`map-container ${true ? '' : 'hidden'}`} onClick={handleMapClick}>
            <Map />
          </div>
        } />
        <Route path="/data" element={
          <div className={`data-summary-container ${false ? 'hidden' : ''}`}>
            <DataSummary isMapVisible={isMapVisible} />
          </div>
        } />
        <Route path="/game" element={<Game ref={gameRef} />} />
      </Routes>
      <BottomBar />
      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
};

const App = () => (
  <AppProvider>
    <Router>
      <AppContent />
    </Router>
  </AppProvider>
);

export default App;