import React, { useState } from 'react';

const ConsentBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleAccept = () => {
    // Record user consent
    localStorage.setItem('userConsent', 'accepted');
    setIsVisible(false);
    // Initialize analytics tracking here
    console.log('User accepted consent. Analytics tracking initialized.');
  };

  const handleDecline = () => {
    console.log('User declined consent.');
    // Redirect to Google
    window.location.href = 'https://www.google.com';
  };

  if (!isVisible) return null;

  return (
    <div className="consent-banner">
    <p>We use cookies to improve your experience and for analytics. By using our site, you consent to cookies.</p>
    <div className="button-container">
      <button onClick={handleAccept}>Accept</button>
      <button onClick={handleDecline}>Decline</button>
    </div>
  </div>
  );
};

export default ConsentBanner;