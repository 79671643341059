import React, { useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { loadCsv } from '../utils/loadCsv';
import { AppContext } from '../AppContext';
import DataSummary from './DataSummary';

const Map = () => {
  const mapContainer = useRef(null);
  const { clickedFeature, setClickedFeature, setCapacityResults } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    const map = new maplibregl.Map({
      container: mapContainer.current,
      style: 'https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json', // Dark Matter style
      center: [-3.5, 56.5],
      zoom: 5,
      minZoom: 1, // Set the minimum zoom level
      maxZoom: 20, // Set the maximum zoom level
      attributionControl: false // Disable the default attribution control
    });

    // Load and parse the CSV file
    loadCsv('/UK_projectcapacity_jittered_output.csv').then((data) => {
      // Convert CSV data to GeoJSON
      const geojson = {
        type: 'FeatureCollection',
        features: data.map((row) => {
          const longitude = parseFloat(row.Longitude);
          const latitude = parseFloat(row.Latitude);
        
          // TODO: jitter coordinates if duplicate
            
          // Check if coordinates are valid numbers
          if (isNaN(longitude) || isNaN(latitude)) {
            console.error('Invalid coordinates:', row);
            return null;
          }

          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [longitude, latitude],
            },
            properties: {
              ProjectName: row.ProjectName,
              PhaseName: row.PhaseName,
              CapacityMW: parseFloat(row.CapacityMW), 
              Technology: row.Technology,
            },
          };
        }).filter(feature => feature !== null), // Filter out invalid features
      };

      // Define color mapping for different technologies
      const colorMapping = {
        Solar: '#FFD700', // Gold
        Wind_onshore: '#00BFFF', // DeepSkyBlue
        Wind_offshore: '#0000FF', // Blue
        // Add more mappings as needed
      };

      // Add GeoJSON source to the map
      map.on('load', () => {
        map.addSource('projects', {
          type: 'geojson',
          data: geojson,
        });

        // Add a layer to display the points
        map.addLayer({
          id: 'projects',
          type: 'circle',
          source: 'projects',
          paint: {
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['number', ['get', 'CapacityMW']],
              0, 4, // Smallest size for CapacityMW = 0-100
              100, 8, // Medium size for CapacityMW = 100-500
              500, 12 // Largest size for CapacityMW > 500
            ],
            'circle-color': [
              'match',
              ['get', 'Technology'],
              'Solar', colorMapping.Solar,
              'Wind_onshore', colorMapping.Wind_onshore,
              'Wind_offshore', colorMapping.Wind_offshore,
              /* Add more mappings here */
              '#FF0000' // Default color (red) if no match
            ],
          },
        });

        // Add a transparent layer around the points for easier clicking
        map.addLayer({
          id: 'projects-click-area',
          type: 'circle',
          source: 'projects',
          paint: {
            'circle-radius': 10, // Larger radius for the clickable area
            'circle-color': 'rgba(0, 0, 0, 0)', // Transparent color
          },
        });

        // Add a popup on click
        map.on('click', 'projects-click-area', (e) => {
          
          const coordinates = e.features[0].geometry.coordinates.slice();
          const { ProjectName, PhaseName, CapacityMW, Technology } = e.features[0].properties;
        
          // Log the properties to debug
          console.log('Clicked feature properties:', {ProjectName}, {PhaseName}, {CapacityMW}, {Technology} );

          setClickedFeature({ ProjectName, PhaseName, CapacityMW, Technology });
        });

        // Change the cursor to a pointer when over the points
        map.on('mouseenter', 'projects', () => {
          map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back when it leaves
        map.on('mouseleave', 'projects', () => {
          map.getCanvas().style.cursor = '';
        });

        // Create and add the legend
        const legend = document.createElement('div');
        legend.className = 'legend';
        legend.innerHTML = `
          <h4>Legend</h4>
          <div><span style="background-color: ${colorMapping.Solar};"></span> Solar</div>
          <div><span style="background-color: ${colorMapping.Wind_onshore};"></span> Wind Onshore</div>
          <div><span style="background-color: ${colorMapping.Wind_offshore};"></span> Wind Offshore</div>
        `;
        mapContainer.current.appendChild(legend);
      });
    });

    return () => map.remove();
  }, [setClickedFeature]);

  useEffect(() => {
    if (!clickedFeature) return;

    const sendCapacityRequest = async () => {
      const url = 'https://us-central1-falafel-426018.cloudfunctions.net/calc_poweredhouses';
      const data = {
        capacity: clickedFeature.CapacityMW,
        technology: clickedFeature.Technology,
        year: clickedFeature.year,
        geography: clickedFeature.geography,
      };

      try {
        console.log('Sending request with data:', data); // Log the data being sent
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log('Response received:', result); // Log the response
        setCapacityResults(result); // Store the results in the context
        navigate('/data'); // Navigate to /data after the request is complete
      } catch (error) {
        console.error('Error:', error);
        // Set default value in case of error
        setCapacityResults({ houses: 'N/A' });
      }
    };

    sendCapacityRequest();
  }, [clickedFeature, setCapacityResults, navigate, setClickedFeature]);

  return (
    <div ref={mapContainer} style={{ height: "100%" }} />
  );
};

export default Map;