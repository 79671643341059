import React, { useEffect, useRef, useContext, useState } from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { AppContext } from '../AppContext';

const MoreInfoModal = ({ isVisible, onClose, targetHouses, settlementName }) => {
  const mapContainer = useRef(null);
  const mapInstance = useRef(null);
  const { lageojson } = useContext(AppContext);
  const [isMapInitialized, setIsMapInitialized] = useState(false);

  const initializeMap = () => {
    if (!mapContainer.current || isMapInitialized) return;

    console.log('Initializing map in MoreInfoModal...');

    mapInstance.current = new maplibregl.Map({
      container: mapContainer.current,
      style: 'https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json', // Dark Matter style
      center: [-3.5, 56.5],
      zoom: 5,
      minZoom: 1, // Set the minimum zoom level
      maxZoom: 20, // Set the maximum zoom level
      attributionControl: false // Disable the default attribution control
    });

    mapInstance.current.on('load', () => {
      console.log('Map loaded successfully in MoreInfoModal');

      // Add lageojson as a source and layer
      if (lageojson) {
        mapInstance.current.addSource('lageojson', {
          type: 'geojson',
          data: lageojson,
        });

        mapInstance.current.addLayer({
          id: 'lageojson-layer',
          type: 'fill',
          source: 'lageojson',
          paint: {
            'fill-color': '#0080FF',
            'fill-opacity': 1,
          },
        });

        // Add a line layer to smooth the edges
mapInstance.current.addLayer({
    id: 'lageojson-line-layer',
    type: 'line',
    source: 'lageojson',
    paint: {
      'line-color': '#0080FF',
      'line-width': 2,
    },
  });

        console.log('lageojson added to the map');

        // Center the map on the GeoJSON data
        const geojsonSource = mapInstance.current.getSource('lageojson');
        if (geojsonSource) {
          const bounds = new maplibregl.LngLatBounds();
          lageojson.features.forEach(feature => {
            feature.geometry.coordinates[0].forEach(coord => {
              bounds.extend(coord);
            });
          });
          mapInstance.current.fitBounds(bounds, { padding: 20, maxZoom: 7});
        }
      }
    });

    mapInstance.current.on('error', (e) => {
      console.error('Map error in MoreInfoModal:', e);
    });

    setIsMapInitialized(true);
  };

  useEffect(() => {
    if (!isVisible) {
      setIsMapInitialized(false);
    }
  }, [isVisible]);

  return (
    <Modal open={isVisible} onClose={onClose} onTransitionEnd={initializeMap}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '75%', sm: 600 }, // Responsive width (half the original size)
          height: { xs: '40%', sm: 400 }, // Responsive height (half the original size)
          bgcolor: '#f5f5f5', // Off-white background color
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          zIndex: 1300, // Ensure the modal is on top
        }}
      >
        <IconButton
        onClick={onClose}
        sx={{ position: 'absolute', top: 8, right: 8 }}
      >
        <CloseIcon />
      </IconButton>
        <Typography variant="h5" component="h2" style={{textAlign:"center"}}>
        {settlementName} has {Math.round(targetHouses).toLocaleString()} households
        </Typography>
        <div
          ref={mapContainer}
          style={{
            height: 'calc(100% - 100px)', // Adjust height to fit within the modal
            marginTop: '20px',
            zIndex: 1200, // Ensure the map container is below the modal but above other content
            backgroundColor: 'lightgray', // Temporary background color for debugging
          }}
        />
      </Box>
    </Modal>
  );
};

export default MoreInfoModal;