import React, { useContext, useState, useEffect, forwardRef, useRef,useImperativeHandle } from 'react';
import { AppContext } from '../AppContext';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Box,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import ScoreBoardModal from './ScoreBoardModal'; // Import the ScoreBoardModal component
import MoreInfoModal from './MoreInfoModal'; // Import the MoreInfoModal component
import BottomBar from './BottomBar';



const Game = forwardRef((props, ref) => {
  const { addedSites, targetHouses, settlementName } = useContext(AppContext);
  const [showTarget, setShowTarget] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMoreInfoModalVisible, setIsMoreInfoModalVisible] = useState(false); // State for the new modal
  const gameContainerRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openModal: () => setIsModalVisible(true),
    closeModal: () => setIsModalVisible(false),
    getGameContainer: () => gameContainerRef.current,
  }));

 const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };


  const handleMoreInfoClick = () => {
    setIsMoreInfoModalVisible(true); // Show the new modal
  };

  const closeMoreInfoModal = () => {
    setIsMoreInfoModalVisible(false);
  };

  useEffect(() => {
    console.log(settlementName, targetHouses);
  }, [settlementName, targetHouses]);

  // Calculate the difference between the target and the sum of houses
  const difference = targetHouses - addedSites.reduce((sum, site) => sum + site.houses, 0);

  // Calculate the sum of houses
  const sumOfHouses = addedSites.reduce((sum, site) => sum + site.houses, 0);

  const handleRevealClick = () => {
    setShowTarget(true);
  };

  useEffect(() => {
    if (addedSites.length === 3) {
      setIsModalVisible(true);
    }
  }, [addedSites]);

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div ref={gameContainerRef} className="GameResults">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1 style={{ marginRight: '10px' }}>🎯 {settlementName}</h1>
        {!showTarget && (
          <button onClick={handleMoreInfoClick} className='question-button'>
            &#x3F; {/* Unicode character for question mark */}
          </button>
        )}
        {showTarget && (
          <p style={{ marginLeft: '10px', marginBottom: '15px' }}>
            {Math.round(targetHouses).toLocaleString()}
          </p>
        )}
      </div>

      {addedSites.length <= 3 && (
        <>
<Box className="game-container">
  <h3 className="table-title">Sites <hr className="white-hr" /></h3>

  <TableContainer component={Paper} className='table-container'>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ padding: '8px' }}></TableCell>
                  <TableCell style={{ padding: '8px', fontWeight: 'bolder' }}>Name</TableCell>
                  <TableCell style={{ padding: '8px', fontWeight: 'bolder' }}>Households</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addedSites.map((site, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ padding: '20px' }}>
                      <div
                        style={{
                          marginLeft: '0px',
                          width: '20px',
                          height: '20px',
                          borderRadius: '50%',
                          backgroundColor: site.technology === 'Solar' ? '#FFD700' : site.technology === 'Wind_offshore' ? '#0000FF' : '#00BFFF',
                        }}
                      ></div>
                    </TableCell>
                    <TableCell style={{ padding: '8px' }}>
                      {site.projectName.replace(/solar farm|wind farm/gi, '').trim()}
                    </TableCell>
                    <TableCell style={{ padding: '8px' }}>
                      {Math.round(site.houses).toLocaleString()}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          </Box>
          {addedSites.length >= 1 && (
            // <div className='ScoreBoard'>
            //   <h3>Scoreboard<hr className="white-hr" /></h3>
            //   <p>Households powered: {Math.round(sumOfHouses).toLocaleString()}</p>
            // </div>
            <div className='ScoreBoard'>
            <h3>
          Summary
          <IconButton 
          onClick={handleOpenModal} 
          sx={{ ml: 1 }} 
          color="inherit" 
          className="black-icon"
          disabled={addedSites.length < 3}
          >
            <InfoIcon />
          </IconButton>
          <hr className="white-hr" />
        </h3>
        {addedSites.length === 3 && (
                <>
                  <p>Powered: {Math.round(sumOfHouses).toLocaleString()}</p>
                  <p>Target: {Math.round(targetHouses).toLocaleString()}</p>
                </>
              )}
        <p>Difference: {Math.round(difference).toLocaleString()}</p>
      </div>
          )}
          <ScoreBoardModal
            isVisible={isModalVisible}
            onClose={closeModal}
            sumOfHouses={sumOfHouses}
            difference={difference}
            addedSites={addedSites}
            gameRef={gameContainerRef}
          />
        </>
      )}
      <MoreInfoModal
        isVisible={isMoreInfoModalVisible}
        onClose={closeMoreInfoModal}
        targetHouses={targetHouses}
        settlementName={settlementName}
      />
    </div>

);
});

export default Game;