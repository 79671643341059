import React, { useState } from 'react';
import ConsentBanner from './ConsentBanner'; // Adjust the import path as needed

const Home = () => {

  const [isInstructionsOpen, setIsInstructionsOpen] = useState(false);
  const [isAcknowledgementsOpen, setIsAcknowledgementsOpen] = useState(false);

  const toggleInstructions = () => {
    setIsInstructionsOpen(!isInstructionsOpen);
  };

  const toggleAcknowledgements = () => {
    setIsAcknowledgementsOpen(!isAcknowledgementsOpen);
  };

  return (
    <div className='data-summary-container'>
      <ConsentBanner />

      <h1 > 
        Power up Britain 
        <hr className="white-hr" />
      </h1>
      <p className="padded-paragraph" style={{paddingBottom:"30px"}}>
        See if you can power a British local authority (e.g. City of Edinburgh) to within 1,000 households using three renewable power sites
      </p>
      <hr className="white-hr"/>

      <div onClick={toggleInstructions} className='moredeats'>
        Instructions {isInstructionsOpen ? '↑' : '↓'}
      </div>
      {isInstructionsOpen && (
        <div className="accordion-content">
          <ul>
            <li>Check the <div className='toggle-button'> ScoreBoard </div> tab to see which British local authority is the target 🎯</li>
            <li style={{ paddingBottom: '10px' }}>If you want to make it a bit easier then click the <div className='question-button'>?</div> icon for how many households are in that local authority</li>
            <li>Once you have clicked on a project on the <div className='toggle-button'> Map </div> tab you will see the data summary</li>
            <li>On the data summary if you want to add the site then click the <div id='addbutton' className='button-common'>+</div> icon</li>
            <li>If you click on the <div id='addbutton' className='button-common'>+</div> it will be added as 1 of your 3 sites, shown in your <div className='toggle-button'> ScoreBoard </div> tab</li>
          </ul>
        </div>
      )}

      <div onClick={toggleAcknowledgements} className='moredeats'>
        Acknowledgements {isAcknowledgementsOpen ? '↑' : '↓'}
      </div>
      {isAcknowledgementsOpen && (
        <div className="accordion-content">
          <p>This game shows how the capacity of renewable energy projects in the Great Britain relates to the <u>potential</u> number of households powered during the last ~15 years. It is built using the following datasets:</p>
          <ul>
            <li><a href="https://globalenergymonitor.org/projects/global-wind-power-tracker/download-data/" target="_blank" rel="noopener noreferrer">Renewable Projects</a></li>
            <li><a href="https://www.gov.uk/government/collections/sub-national-electricity-consumption-data" target="_blank" rel="noopener noreferrer">Annual household consumption</a></li>
            <li><a href="https://assets.publishing.service.gov.uk/media/66a7d52149b9c0597fdb06bd/DUKES_6.3.xlsx" target="_blank" rel="noopener noreferrer">Technology Load Factors</a></li>
            <li><a href="https://services1.arcgis.com/ESMARspQHYMw9BZ9/ArcGIS/rest/services/Local_Authority_Districts_December_2023_Boundaries_UK_BFE/FeatureServer/0" target="_blank" rel="noopener noreferrer">Local Authority District boundaries</a></li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Home;