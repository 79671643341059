import React from 'react';

const BottomBar = () => {
  return (
<div style={{ position: 'fixed', bottom: "0", width: '100%', display: 'flex', justifyContent: 'right', alignItems: 'center', padding: '10px', zIndex: 100 }}>
  <a href='https://www.linkedin.com/in/mark-cherrie-09009040/' style={{ paddingRight: '30px', paddingBottom: '0px'}}>
  <img src='newlogo_inverse.png' alt='Logo' style={{ height: '30px' }} />
  </a>
</div>
  );
};

export default BottomBar;