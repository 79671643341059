import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../AppContext';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import ConsentBanner from './ConsentBanner';

const DataSummary = () => {
  const navigate = useNavigate();
  const { clickedFeature, capacityResults, setClickedFeature, addedSites, setAddedSites } = useContext(AppContext);
  const [selectedYear, setSelectedYear] = useState('2022');
  const [selectedGeography, setSelectedGeography] = useState('GreatBritain');
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isInstructionsOpen, setIsInstructionsOpen] = useState(true);
  const [isAddSiteOpen, setIsAddSiteOpen] = useState(false);
  const [isCountUpFinished, setIsCountUpFinished] = useState(false);

  useEffect(() => {
    console.log('clickedFeature:', clickedFeature);
  }, [clickedFeature]);

  const handleYearChange = (e) => {
    const year = parseInt(e.target.value, 10);
    setSelectedYear(year);
    setClickedFeature({ ...clickedFeature, year });
  };

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const toggleInstructions = () => {
    setIsInstructionsOpen(!isInstructionsOpen);
  };

  const handleGeographyChange = (e) => {
    const geography = e.target.value;
    setSelectedGeography(geography);
    setClickedFeature({ ...clickedFeature, geography });
  };

  const handleAddSite = () => {
    if (clickedFeature && capacityResults && addedSites.length < 3) {
      const newSite = {
        projectName: clickedFeature.ProjectName,
        technology: clickedFeature.Technology,
        houses: capacityResults.houses
      };
  
      // Check for duplicates
      const isDuplicate = addedSites.some(site => site.projectName === newSite.projectName && site.technology === newSite.technology);
      if (!isDuplicate) {
        setAddedSites([...addedSites, newSite]);
        toast('Added Site', {
          icon: '👏',
        });
        navigate('/game');
        return;
      }
      toast('You already have this site', {
        icon: '✋',
      });
    }
  
  };
  return (
    <div className="data-summary-container">
      <div className="capacity-circle">
        {clickedFeature ? clickedFeature.CapacityMW : 'N/A'} MW
      </div>
      <div>
        <h1 className='Title'>
          {clickedFeature ? clickedFeature.ProjectName : 'No Project Selected'}
          {clickedFeature && clickedFeature.PhaseName !== '--' && ` ${clickedFeature.PhaseName}`}
        </h1>
      </div>
      <div className="centered-content">
        {capacityResults && capacityResults.houses !== 'N/A' ? (
          <CountUp
            start={0}
            end={Math.round(capacityResults.houses)}
            duration={1}
            onEnd={() => {
              setTimeout(() => {
                setIsCountUpFinished(true);
              }, 250); // 0.5 second delay
            }}
          />
        ) : (
          <p>N/A</p>
        )}
        <p className='households'>households</p>
        {isCountUpFinished && (
          <button id='addbutton2' className='button-common' onClick={handleAddSite}>+</button>
        )}
      </div>
        <hr className="white-hr" />
      <div onClick={toggleSettings} className='moredeats'>
        Settings {isSettingsOpen ? '↑' : '↓'}
      </div>
      {isSettingsOpen && (
        <div className="accordion-content">
          <label>
            The number of households in
            <select className="styled-select" value={selectedGeography} onChange={handleGeographyChange}>
              <option value=""> Select Geography</option>
              <option value="GreatBritain"> Great Britain</option>
              <option value="EnglandandWales"> England and Wales</option>
              <option value="England"> England</option>
              <option value="NorthEast"> North East</option>
              <option value="NorthWest"> North West</option>
              <option value="YorkshireandtheHumber"> Yorkshire and the Humber</option>
              <option value="EastMidlands"> East Midlands</option>
              <option value="WestMidlands"> West Midlands</option>
              <option value="East"> East</option>
              <option value="London"> London</option>
              <option value="InnerLondon"> Inner London</option>
              <option value="OuterLondon"> Outer London</option>
              <option value="SouthEast"> South East</option>
              <option value="SouthWest"> South West</option>
              <option value="Wales"> Wales</option>
              <option value="Scotland_incunallocated"> Scotland</option>
              {/* Add more geographies as needed */}
            </select> powered by the selected project in
            <select className="styled-select2" value={selectedYear} onChange={handleYearChange}>
              <option value=""> Select Year</option>
              <option value="2007"> 2007</option>
              <option value="2008"> 2008</option>
              <option value="2009"> 2009</option>
              <option value="2010"> 2010</option>
              <option value="2011"> 2011</option>
              <option value="2012"> 2012</option>
              <option value="2013"> 2013</option>
              <option value="2014"> 2014</option>
              <option value="2015"> 2015</option>
              <option value="2016"> 2016</option>
              <option value="2017"> 2017</option>
              <option value="2018"> 2018</option>
              <option value="2019"> 2019</option>
              <option value="2020"> 2020</option>
              <option value="2021"> 2021</option>
              <option value="2022"> 2022</option>
            </select>
          </label>
        </div>
      )}
      <div onClick={toggleAccordion} className='moredeats'>
        Assumptions {isAccordionOpen ? '↑' : '↓'}
      </div>
      {isAccordionOpen && (
        <div>
          <p>Load Factor: {capacityResults ? capacityResults.load : 'N/A'} %</p>
          <p>Network transmission and distribution losses: 5.15 %</p>
          <p>Max Annual Generation: {capacityResults ? Math.round(capacityResults.max_possible_generation).toLocaleString() : 'N/A'} KWh</p>
          <p>Actual Generation: {capacityResults ? Math.round(capacityResults.actual_generation).toLocaleString() : 'N/A'} KWh</p>
          <p>Household Annual Consumption: {capacityResults ? Math.round(capacityResults.annual_consumption).toLocaleString() : 'N/A'} KWh</p>
        </div>
      )}
    </div>
  );
};

export default DataSummary;