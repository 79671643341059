import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import html2canvas from 'html2canvas';
import CloseIcon from '@mui/icons-material/Close';

/// TODO: add a logo to the bottom right 
/// add the score to the scoreboard bit

const ScoreBoardModal = ({ isVisible, onClose, gameRef, addedSites, sumOfHouses, difference }) => {
  const [score, setScore] = useState(0);
  const [bonus, setBonus] = useState("False");

  const handleCaptureClick = async () => {
    if (gameRef.current) {
      const canvas = await html2canvas(gameRef.current, {
        backgroundColor: '#000000', // Set the background color to black
        useCORS: true, // Enable cross-origin resource sharing if needed
      });

      console.log(canvas.height, canvas.width);
      
      /// get the h to w ratio
      const ratio = canvas.height / canvas.width;

      /// use height as 1368 and then scale width
      const desiredHeight = 1368; // Set your desired height
      const desiredWidth = desiredHeight / ratio;

      // Create a new canvas with the desired dimensions
      const resizedCanvas = document.createElement('canvas');
      resizedCanvas.width = desiredWidth;
      resizedCanvas.height = desiredHeight;
      const resizedContext = resizedCanvas.getContext('2d');

      // Draw the original canvas onto the resized canvas
      resizedContext.drawImage(canvas, 0, 0, desiredWidth, desiredHeight);

      // Add text to the bottom right corner
      const text = 'renewableinsights.co.uk';
      const fontSize = 30; // Set the font size
      resizedContext.font = `${fontSize}px Arial`;
      resizedContext.fillStyle = 'white'; // Set the text color
      const textWidth = resizedContext.measureText(text).width;
      const xPosition = desiredWidth - textWidth - 40; // 20px padding from the right
      const yPosition = desiredHeight - 20; // 20px padding from the bottom
      resizedContext.fillText(text, xPosition, yPosition);

      const formattedScore = score.toFixed(1); // Format the score to 1 decimal place

      // Use smaller ratios and set maximum sizes
      const scoreFontSize = Math.min(desiredHeight * 0.05, 60); // 5% of the canvas height or 60px, whichever is smaller
      const scoreXPosition = desiredWidth * 0.87; // Center horizontally
      const scoreYPosition = desiredHeight * 0.09; // 10% from the top
      
      // Draw the softer red circular border
      const circleRadius = Math.min(desiredHeight * 0.05, 60); // 5% of the canvas height or 60px, whichever is smaller
      const circleYOffset = desiredHeight * 0.02; // 2% of the canvas height
      resizedContext.beginPath();
      resizedContext.arc(scoreXPosition, scoreYPosition + circleYOffset, circleRadius, 0, 2 * Math.PI);
      resizedContext.fillStyle = 'black'; // Fill color
      resizedContext.fill();
      resizedContext.strokeStyle = '#007bff'; 
      resizedContext.lineWidth = Math.min(desiredHeight * 0.005, 5); // 0.5% of the canvas height or 5px, whichever is smaller
      resizedContext.stroke();
      
      // Draw the score text inside the circle
      resizedContext.font = `${scoreFontSize}px Arial`;
      resizedContext.fillStyle = '#007bff'; // Softer red color (Tomato)
      const scoreTextWidth = resizedContext.measureText(formattedScore).width;
      resizedContext.fillText(formattedScore, scoreXPosition - scoreTextWidth / 2, scoreYPosition + circleYOffset + scoreFontSize / 3);
      const imgData = resizedCanvas.toDataURL('image/png');

      // Create a link element to download the image
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'RenewableInsightsGame.png';
      link.click();

      // Optionally, you can share the image on social media
      // For example, using the Web Share API (if supported)
      if (navigator.share) {
        const blob = await (await fetch(imgData)).blob();
        const file = new File([blob], 'RenewableInsightsGame.png', { type: 'image/png' });

        navigator.share({
          title: 'Check out my Renewable Insights screenshot!',
          text: 'Here is a screenshot of my game at renewableinsights.co.uk',
          files: [file],
        }).catch((error) => console.error('Error sharing:', error));
      }
    }
  };

  useEffect(() => {
    if (difference > 100000 || difference < -100000) {
      difference = 100000;
    }

    // Normalize the difference to a value between 0 and 1
    const normalizedDifference = Math.abs(difference) / 100000;

    // Calculate the score based on the normalized difference
    let calculatedScore = 9 - (normalizedDifference * 9);

    // Check if addedSites includes all three technologies
    const technologies = addedSites.map(site => site.technology);
    const hasAllTechnologies = ['Solar', 'Wind_offshore', 'Wind_onshore'].every(tech => technologies.includes(tech));

    // Add a 1 point bonus if all three technologies are included
    if (hasAllTechnologies) {
      calculatedScore += 1;
      setBonus("True");
    }

    // Ensure the score does not exceed 9
    calculatedScore = Math.min(calculatedScore, 10);

    // Round the score to 1 decimal place
    calculatedScore = Math.round(calculatedScore * 10) / 10;

    // Update the score state
    setScore(calculatedScore);

    /// TODO: set score context so can access for screenshot

  }, [addedSites, difference]);

  if (!isVisible) return null;

  return (
    <Modal open={isVisible} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton
        onClick={onClose}
        sx={{ position: 'absolute', top: 8, right: 8 }}
      >
        <CloseIcon />
      </IconButton>
        <Typography variant="h6" component="h2">
          {difference < 0 
            ? "You've overshot  ... better luck tomorrow!" 
            : Math.abs(difference) <= 1000
              ? 'Congratulations! You did it ... now can you do it again tomorrow!' 
              : `You've undershot  ... better luck tomorrow!`}
        </Typography>
        <Typography sx={{ mt: 2 }}>
        {
          difference < 0
            ? `You were over by ${Math.abs(Math.round(difference)).toLocaleString()} households`
            : `You were under by ${Math.round(difference).toLocaleString()} households`
        }
        </Typography>
        {bonus === "True" && <Typography sx={{ mt: 2 }}>Multi Technology Bonus: ☑️</Typography>}
        <Typography sx={{ mt: 2 }}>Final score: {score}</Typography>
        <Button onClick={handleCaptureClick} variant="contained" color="secondary" sx={{ mt: 2, textTransform: 'none'  }}>
          Capture and Share
        </Button>
      </Box>
    </Modal>
  );
};

export default ScoreBoardModal;